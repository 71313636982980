import React, { useState } from 'react';
import AttractionsList from "../components/AttractionsList";

const faqData = [
    {
        question: "Where is the college located?",
        answer: "It is located on Bypass road, near Sai Baba Temple."
    },
    {
        question: "How is the connectivity near the college?",
        answer: "5 minute drive to Agartala Railway Station, 15 minute walk to Amtali Bazar where there are a variety of shops and the main bus stand as well, 40 minutes direct bus from Maharaja Bir Vikram Airport, Agartala."
    },
    {
        question: "Where is the hostel located?",
        answer: "Hostel is located within the college building. No travel is required to attend classes. Food is provided within the Hostel."
    },
    {
        question: "What about the safety of students living in the hostel?",
        answer: "Hostel is equipped with 24/7 on guard security and CCTV around the campus for student safety."
    },
    {
        question: "What does hostel fees include?",
        answer: "Hostel fees includes fooding and a fully secured residence to live in."
    }
];

const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b-2 border-gray-200 py-4">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-left">{question}</h2>
                <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
                    <svg
                        className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>
            </div>
            {isOpen && <p className="mt-2 text-gray-900 text-left">{answer}</p>}
        </div>
    );
};

const FAQSection = () => {
    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-6">Frequently Asked Questions</h1>
            {faqData.map((item, index) => (
                <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}

        <AttractionsList/>




        </div>
    );
};

export default FAQSection;
