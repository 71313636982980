import React from 'react';

const RulesRegulationsPage = () => {
    return (
        <div className="bg-white text-black py-8 min-h-screen lg:py-12 ">
            <div className="container mx-auto px-4 lg:px-64">
                <h1 className="text-3xl lg:text-4xl font-bold mb-6 text-center bg-white text-gray-800 py-2 rounded-lg">Rules & Regulations</h1>

                {/* Academic */}
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 text-center bg-primary text-white py-2 rounded-lg">Academic</h2>
                    <ul className="list-disc pl-6 text-left">
                        <li>Students need to be punctual for theory classes and clinical practice according to the schedule given by the institution.</li>
                        <li>Students have to submit all assignments exactly on specified dates except in genuine unavoidable circumstances with prior permissions.</li>
                        <li>No re-examination will be conducted for those who fail in the sessional examinations.</li>
                        <li>Students will be allowed to appear in the examinations in the subjects in which they have 80% attendance of the total number of theory classes taken.</li>
                        <li>Students are not allowed to use mobile phones in the college premises, during class hours, or during clinical experience hours.</li>
                    </ul>
                </div>

                {/* Code of Conduct */}
                <div className="mb-8">
                    <h2 className="text-xl font-semibold mb-2 text-center bg-primary text-white py-2 rounded-lg">Code of Conduct</h2>
                    <ol className="list-decimal pl-6 text-left">
                        <li>Students are not allowed to paste notices within the Institutions and are forbidden to communicate with any outside authority directly.</li>
                        <li>Strictly forbidden in any part of the institution are the possession or use of alcoholic beverages, addictive drugs, gambling, firearms, or any lethal weapons.</li>
                        <li>Ragging of any student is prohibited and may result in rustication or expulsion from the Institute.</li>
                        <li>All fees must be paid in full by the dates notified from time to time.</li>
                        <li>All neglect of duties, breach of rules, and acts of indiscipline are to be brought to the notice of the Principal/Director.</li>
                    </ol>
                </div>

                {/* Hostel Rules & Regulations */}
                <div>
                    <h2 className="text-xl font-semibold mb-2 text-center bg-primary text-white py-2 rounded-lg">Hostel Rules & Regulations</h2>
                    <ul className="list-disc pl-6 text-left">
                        <li>All the students are instructed to be physically present for the night attendance. In case of medical reasons, concerned students or roommates/friends have to inform the Hostel In-Charge/caretaker.</li>
                        <li>Fines must be reported to the hostel in-charge timely.</li>
                        <li>Students should remain in their own rooms after 11.00 PM. No student is permitted to roam around in the corridor after 11.00 PM.</li>
                        <li>Students are instructed to switch off lights and fans before leaving the room.</li>
                        <li>Students should handle all hostel equipment carefully.</li>
                        <li>Students are permitted to watch TV up to 09.30 PM in the common hall on holidays without causing disturbance.</li>
                        <li>Students themselves are responsible for their personal belongings.</li>
                        <li>Students are instructed to keep hostel rooms and its premises neat and clean.</li>
                        <li>Students are not permitted to write, paste, or hang anything on the hostel walls or doors.</li>
                        <li>All students should reach class/college/clinical duty 5 minutes before.</li>
                        <li>No visitors will be allowed without the permission of the Hostel In-Charge.</li>
                        <li>Ragging is strictly prohibited and punishable.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default RulesRegulationsPage;
