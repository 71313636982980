import React, {useState} from 'react';
import ProgramCard from "../components/ProgramCard";
import {Strings} from "../assets/string";
import AdmissionProcedure from "../components/AdmissionProcedure";
import ProgramCardModal from "../components/Programs/ProgramCardModal";
import ProgramCardSummary from "../components/Programs/ProgramCardSummary";
import {ImageDir} from "../assets/imageDir";

const CoursesPage = () => {
    const [expandedProgram, setExpandedProgram] = useState(null);

    const handleExpand = (programIndex) => {
        setExpandedProgram(programIndex);
    };

    const handleCloseModal = () => {
        setExpandedProgram(null);
    };

    return (
        <div className="bg-white min-h-screen p-8 md:pt-16 sm:pt-4 text-center">
            <div className="max-w-3xl mx-auto">
                <h1 className={"text-black text-4xl font-extrabold mb-8"}>{'Courses Offered'}</h1>

                <div className="flex flex-wrap justify-center">
                    {(ImageDir.programs).map((program, index) => (
                        <div key={index} className="w-full sm:w-1/2 lg:w-1/2 px-4">
                            <ProgramCardSummary
                                program={program}
                                onExpand={() => handleExpand(index)}
                            />
                        </div>
                    ))}
                    {/*<div className="w-full sm:w-1/2 lg:w-1/3 px-4">*/}
                    {/*    div button with bg-scondar*/}
                    {/*    <div className="bg-secondary h-full rounded-lg shadow-lg mb-8">*/}
                    {/*        <a href="/admission">Apply Now</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                {expandedProgram !== null && (
                    <ProgramCardModal
                        program={(ImageDir.programs)[expandedProgram]}
                        onClose={handleCloseModal}
                    />
                )}

                {/*<div className="flex flex-wrap justify-center">*/}
                {/*    {programs.map((program, index) => (*/}
                {/*        <ProgramCard program={program} key={index}/>*/}
                {/*    ))}*/}

                {/*</div>*/}
                <AdmissionProcedure/>
            </div>


        </div>
    );
};

export default CoursesPage;
