import React from 'react';
import Banner from "../components/Banner";
import building from "../assets/images/bulid.jpeg";
import {Strings} from "../assets/string";
import BannerF from "../components/BannerF";

import dean from "../assets/images/WhatsApp Image 2024-03-12 at 20.20.03 (6).jpeg";
import TextBanner from "../components/TextBanner";
import OtherFacilitiesBanner from "../components/OtherFacilitiesBanner";
import coverImages from "../assets/images/WhatsApp Image 2024-03-12 at 20.20.00 (3).jpeg";
import {ImageDir} from "../assets/imageDir";

function FacilitiesPage() {
    return (
        <div className="bg-gray-100 ">
            {/* Main cover slideshow */}

            <TextBanner
                title={'Facilities'}
                description={"Watch the video to know more about the facilities provided by the college."}
           />
            {/* Slideshow */}
            <div className={'md:px-16 px-4 w-screen'}>
                {/*<img src={ImageDir.faculty} alt="Cover"*/}
                {/*     className="object-cover object-center w-screen h-96  rounded-2xl shadow-2xl"/>*/}


                <iframe
                    src='https://www.youtube.com/embed/_oi5waHV0zA?si=AWg0YFA-JWaji5OI'
                    frameBorder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    className="object-cover object-center w-full h-96  rounded-2xl shadow-2xl"
                />
            </div>

            <TextBanner
                title={'Faculty'}
                description={'The College has a team of dedicated and well-qualified faculty having vast experience. The College also engages Doctors and Part-time visiting Faculty for teaching allied subjects.'}
            >

            </TextBanner>
            {/* Heading */}<Banner
            bg={"bg-primary"}
            image={ImageDir.laboratory}
            header={'Laboratory Facilities'}
            content={'Students have ample opportunities to gain knowledge and practical skills in various laboratories such as Fundamentals of Nursing, Nutrition, Biochemistry, Microbiology, Maternal & Child Health, and Community Health Nursing.'}
        />
            {/* Heading */}<BannerF
            bg={"bg-secondary"}
            image={ImageDir.classroom}
            header={'Classroom Facilities'}
            content={'Learning is imparted in comfortable classrooms equipped with modern teaching aids. The college offers excellent digital classrooms for learning and practical.\n'}
        />
            <Banner
                bg={"bg-primary"}
                image={ImageDir.hostels}
                header="Hostel Facilities"
                content={
                    "Students are provided with suitable well-furnished accommodation within the hostel. Students are taken care of by hostel wardens and caretakers. Round-the-clock security guards' service is also ensured."

                }

            ></Banner>


            <OtherFacilitiesBanner/>
        </div>

    );

}

export default FacilitiesPage;