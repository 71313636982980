import React from 'react';
import BannerWithCards from "./BannerWithCards";

const otherfactext =[
    {
        title: 'Transportation Facilities',
        description: 'NEINS has its own transport system for the students.'
    },
    {
        title: 'Library Facilities',
        description: 'A spacious and well-ventilated library is available with a good capacity. Library operations are computerized. Subjects are classified according to the DDC. The Library computers also have internet facility. Latest books, journals, internet, and photocopying facilities are also available.'

    },
    {
        title: 'Wi-Fi Facilities',
        description: '24 Hours Wi-Fi facility is available all around the college/campus/hostel for educational purposes.'
    },

]


const OtherFacilitiesBanner = () => {
    return (
      <BannerWithCards dir={otherfactext}/>
    );
};

export default OtherFacilitiesBanner;
