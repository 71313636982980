// ProgramCardModal.js
import React from 'react';

const ProgramCardModal = ({ program, onClose }) => {
    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-start lg justify-center p-8">
            <div className="bg-white rounded-lg p-8">
                <div className="mb-4 flex justify-between">
                    <h2 className="text-xl font-semibold">{program.title}</h2>
                    <button className="text-gray-700" onClick={onClose}>
                {/*    close icon*/}
                    <svg
                        className="h-6 w-6 fill-current text-gray-700"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.707 4.293a1 1 0 010 1.414L13.414 12l6.293 6.293a1 1 0 01-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 01-1.414-1.414L10.586 12 4.293 5.707a1 1 0 011.414-1.414L12 10.586l6.293-6.293a1 1 0 011.414 0z"
                        />
                    </svg>
                    </button>
                </div>
                <p className="text-gray-700 mb-4">{program.description}</p>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold mb-2">Eligibility</h3>
                    <ul className="list-disc pl-6 text-left">
                        {program.eligibility.map((el, idx) => (
                            <li key={idx} className="text-gray-700">{el.description}</li>
                        ))}
                    </ul>
                </div>
                <div className="flex justify-between">
                    <p className="text-gray-700"><span className="font-semibold">Duration:</span> {program.duration}</p>
                    <p className="text-gray-700"><span className="font-semibold">Age Limit:</span> {program.age_limit}</p>
                </div>
            </div>
        </div>
    );
};

export default ProgramCardModal;
