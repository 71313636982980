import React from 'react';

const AdmissionProcedure = () => {
    return (
        <div className="container mx-auto py-12 lg:py-8 text-left">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <h1 className="text-3xl lg:text-4xl font-bold mb-6 text-center">Admission Procedure</h1>

                {/* Content */}
                <div className="space-y-8">
                    {/* Submission of Application Form */}
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Submission of Application Form</h2>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>The Eligible Candidates should submit the filled APPLICATION FORM, which will be available from the office of NEINS along with Prospectus.</li>
                            <li>A passport-size photo shall be fixed in the application form. Self-attested Xerox Copy of all relevant certificates and documents should be attached with the application form.</li>
                            <li>The candidates should submit the duly filled application forms on or before the prescribed last date of submission of forms.</li>
                        </ul>
                    </div>

                    {/* Admission Test */}
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Admission Test</h2>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Admit Card should be collected in person. Applicants whose completed application forms have been received by the office of NEINS shall contain the timetable, address of the centre, and details for the Admission test.</li>
                            <li>The Admission Test consists of 100 questions of 1 mark each. These will be of objective type, multiple choice, and short answer questions. There is NO negative marking.</li>
                        </ul>
                    </div>

                    {/* Process of Selection */}
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Process of Selection</h2>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>The results of the Admission Test will be put up on the Notice Board of NEINS.</li>
                            <li>Based on the result of the Admission Written Test, candidates will be shortlisted who will then be required to appear before the selection board for an interview.</li>
                            <li>Selected candidates should have to appear for a Medical test. The cost of the medical test will be borne by the candidate, and a vaccination report should be produced at the time of the Medical Test.</li>
                        </ul>
                    </div>

                    {/* Reservation */}
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Reservation</h2>
                        <p>The selection procedure for candidates in different reservation quotas (SC/ST/OBC) will be strictly followed as per prevailing Govt. Policy. In case reserved candidates are not available, then the quotas will be allotted to general candidates.</p>
                    </div>

                    {/* Rejection and Disqualification */}
                    <div>
                        <h2 className="text-xl font-semibold mb-2">Rejection and Disqualification</h2>
                        <ul className="list-disc pl-6 space-y-2">
                            <li>Application form duly filled in along with necessary enclosures not received.</li>
                            <li>Application form incompletely/wrongly filled in.</li>
                            <li>Certificates and testimonials found to be inaccurate or false.</li>
                            <li>Failing to take the Admission Written Test.</li>
                            <li>Failing to be present for scrutiny of certificates and testimonials, etc., on the due date and time.</li>
                            <li>Failing to submit the college and other fees by the due date.</li>
                            <li>Educational and other requirements of the Indian Nursing Council and of the College are not fulfilled.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdmissionProcedure;
