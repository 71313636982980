// ProgramCardSummary.js
import React from 'react';

const ProgramCardSummary = ({ program, onExpand }) => {
    return (
        <div className="bg-white rounded-lg shadow-lg mb-8">
            <div className="relative">
                {/* Course Cover Picture */}

                <img
                    src={program.coverImageUrl}
                    alt={program.title}
                    className="w-full h-40 object-cover rounded-t-lg"
                />
                {/* Course Name with Background */}
                <div className="absolute inset-x-0 bottom-0 bg-secondary text-white py-1 px-4 ">
                    <h2 className="text-xl font-semibold">{program.title}</h2>
                </div>
            </div>
            <div className="p-6">
                {/* Duration and Age Limit */}
                <div className="flex justify-between mb-4">
                    <p className="text-gray-700"><span className="font-semibold">Duration:</span> {program.duration}</p>
                    <p className="text-gray-700"><span className="font-semibold">Age Limit:</span> {program.age_limit}</p>
                </div>
                {/* Eligibility */}
                {/*<div>*/}
                {/*    <h3 className="text-lg font-semibold mb-2">Eligibility</h3>*/}
                {/*    <ul className="list-disc pl-6">*/}
                {/*        {program.eligibility.map((el, idx) => (*/}
                {/*            <li key={idx} className="text-gray-700">{el.description}</li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/* Button to expand */}
                <button
                    className="bg-gray-800 text-white px-4 py-2 rounded-lg mt-4 w-full focus:outline-none hover:bg-gray-700 transition duration-300 ease-in-out "
                    onClick={onExpand}
                >
                    View Details
                </button>
            </div>
        </div>
    );
};

export default ProgramCardSummary;
