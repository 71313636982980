import React from 'react';

const TextBanner = (props) => {
    return (
        <div className={"py-8 lg:py-12 text-black " + props.className}>
            <div className="container mx-auto px-4">
                <h1 className="text-3xl lg:text-4xl font-bold mb-4">{props.title}</h1>
                <p className="text-lg lg:text-xl">{props.description}</p>
            </div>
        </div>
    );
};

export default TextBanner;
