import React from 'react';

const Banner = (props) => {
    return (
        <div className={" bg-white relative md:mx-32 mx-2 my-4"}>
            <div className={`lg:flex lg:items-center lg:justify-between`}>
                {/* Left Column with Background Image */}
                <div className="lg:w-1/2 lg:pr-16 round">
                    <div className="relative h-64 lg:h-auto">
                        <img
                            src={props.image}
                            alt="Banner Image"
                            className="object-cover object-center w-full h-full lg:w-auto lg:h-auto rounded-2xl shadow-2xl
                          max-h-96


                            "
                        />
                    </div>
                </div>

                {/* Right Column with Text */}
                <div className="lg:w-1/2 px-6 py-12 lg:py-24 lg:pl-0 text-left  text-black">
                    <h1 className="text-4xl lg:text-5xl font-bold mb-4">{props.header}</h1>
                    <p className="text-lg lg:text-xl mb-8">{props.content}</p>
                    {/* Add more text or components as needed */}
                </div>
            </div>
        </div>
    );
};

export default Banner;
