import React, {useEffect, useState} from 'react';

import '../App.css';
import cover1 from '../assets/images/cover1.jpeg';

import { Strings } from "../assets/string";
import { useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import BannerF from "../components/BannerF";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import NavBar from "../components/NavBar";
import TextBanner from "../components/TextBanner";
import BannerWithCards from "../components/BannerWithCards";
import {ImageDir} from "../assets/imageDir";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "red" }}
            onClick={onClick}
        />
    );
}
const features =[
    {
        image:ImageDir.placementsCover,
        title: 'Placements',
        subtitle: 'Opportunities at various cities:',
        description:["West Bengal","Tripura","Guwahati"]


    },

    {
        image:ImageDir.scholarshipCover,
        title: 'Scholarships',
        subtitle: 'Contact for more information',
        description:["Merit based","Need based"]
    },


]

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={className}
            style={{...style, display: "block", background: "green"}}
            onClick={onClick}
        />
    );
}


function HomePage() {
    const navigate = useNavigate();


    // shuffle the cover images array every 5 seconds
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,

        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    // Dummy text for the heading section
    const headingText = "Welcome to NEINS College";

    const awards = require('../assets/images/WhatsApp Image 2024-03-12 at 20.20.03.jpeg');
    const dean = require('../assets/images/WhatsApp Image 2024-03-12 at 20.20.03 (6).jpeg');
    return (
        <div className="bg-white max-w-full">
            {/* Main cover slideshow */}

            {/* Slideshow */}
            <div className={'h-1/2  md:h-1/3 '}>
            {/*    add a overlay dark */}

            <Carousel  className={'flex h-1/2  md:h-1/3 '} showThumbs={false} swipeable={false} autoPlay={true} interval={3000} infiniteLoop={true}>
                {
               (ImageDir.covers).map((cover, index) => (
                   <>
                       <div className={'absolute inset-0 bg-black bg-opacity-40 z-10'}></div>
                       <div className={'relative min-h-fit w-full'} key={index}>
                           <TextBanner
                               title={headingText}
                               description={Strings.Description}
                               className="absolute inset-x-0 bottom-0 px-4 py-8 text-white z-50"
                           />
                           <img src={cover}
                                alt="Cover"

                                className="inset-0 object-cover object-center w-full " style={{height: '77vh'}}/>
                       </div>
                   </>
               ))

                }

            </Carousel>
            </div>


            {/*<Banner*/}
            {/*    bg={"bg-primary"}*/}
            {/*    image={ImageDir.building}*/}
            {/*    header={headingText}*/}
            {/*    content={Strings.Description}*/}
            {/*/>*/}
            <div className="bg-gray-800 text-white py-8">
                <h1 className={"text-2xl font-extrabold text-center mb-4"}>Affiliations & Recognitions</h1>
                <div
                    className="container mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
                    {(ImageDir.aff_json).map((aff, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="w-16 h-16 bg-white rounded-full flex items-center justify-center mb-2">
                                <img src={aff.logo} alt={`Logo ${index + 1}`} className="h-10"/>
                            </div>
                            <p className="text-lg font-semibold
                            {/*make it two lines*/}
                            ">{aff.name}</p>
                        </div>
                    ))}
                </div>

            </div>
            {/* Section with image and heading */}
            {/*<div className="bg-gray-800 py-12 lg:py-24">*/}
            {/*    <div*/}
            {/*        className="container mx-auto flex flex-col  items-center justify-center lg:justify-between">*/}
            {/*        /!* Left Column with Images *!/*/}
            {/*        <div className="flex flex-wrap justify-around mb-8 lg:mb-0 lg:mr-8">*/}
            {/*            <img*/}
            {/*                src="image1.jpg"*/}
            {/*                alt="Institute 1"*/}
            {/*                className="w-32 h-32 object-cover rounded-full border-4 border-white mb-4"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="image2.jpg"*/}
            {/*                alt="Institute 2"*/}
            {/*                className="w-32 h-32 object-cover rounded-full border-4 border-white mb-4"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="image3.jpg"*/}
            {/*                alt="Institute 3"*/}
            {/*                className="w-32 h-32 object-cover rounded-full border-4 border-white mb-4"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="image4.jpg"*/}
            {/*                alt="Institute 4"*/}
            {/*                className="w-32 h-32 object-cover rounded-full border-4 border-white mb-4"*/}
            {/*            />*/}
            {/*            <img*/}
            {/*                src="image5.jpg"*/}
            {/*                alt="Institute 5"*/}
            {/*                className="w-32 h-32 object-cover rounded-full border-4 border-white mb-4"*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        /!* Right Column with Text *!/*/}
            {/*        <div className="lg:w-1/2 lg:pl-8 text-white">*/}
            {/*            <h2 className="text-2xl lg:text-4xl font-bold mb-4">Recognition from Institutes</h2>*/}

            {/*            <p className="text-lg font-semibold">Affiliated by:</p>*/}
            {/*            <p>Directorate Of Medical Education &amp; Tripura University (A Central University)</p>*/}


            {/*            <p className="text-lg font-semibold">Approved by:</p>*/}
            {/*            <p>Govt. of Tripura</p>*/}


            {/*            <p className="text-lg font-semibold">Recognized by:</p>*/}
            {/*            <p>Tripura Nursing Council &amp; Indian Nursing Council</p>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <TextBanner
                title={'Why choose NEINS?'}
                description={"To set our students on the path of success, we provide them with the best facilities and opportunities."}
            />

            {/*map features*/}
            <div className={' bg-white flex flex-col md:flex-row w-screen justify-center gap-8 md:gap-16 lg:gap-32 pb-16 px-8 md:px-16 lg:px-32 '}>
            {
                features.map((feature, index) => {
                    return <div className={'flex flex-row rounded-2xl shadow-md bg-gray-300'}>
                    {/*    add image*/}
                    <img src={feature.image} alt="Cover"
                         className="object-cover object-center w-1/4

                            rounded-l-2xl
                           "/>
                    {/*    add text*/}
                    <div className={'pl-8 py-2 flex flex-col w-full'}>
                        <p className="text-2xl font-bold text-left">{feature.title}</p>
                        <p className="text-left">{feature.subtitle}</p>
                        <div className="text-left">
                            <ul className="list-disc list-inside">
                                {
                                    feature.description.map((point, index) => {
                                        return <li key={index}>{point}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    </div>
                })
            }
            </div>
            {/*<BannerWithCards dir={features}/>*/}


            {/*<BannerF*/}
            {/*    bg={"bg-primary"}*/}
            {/*    image={awards}*/}
            {/*    header="Why Choose NEINS?"*/}
            {/*    content={*/}

            {/*        //    create bullet point with heading and content*/}

            {/*        <div>*/}
            {/*            <div className="mb-4">*/}
            {/*                <h3 className="text-lg lg:text-xl font-semibold mb-2">Placement</h3>*/}
            {/*                <p>Placement Oppurtunities</p>*/}
            {/*            </div>*/}

            {/*            <div className="mb-4">*/}
            {/*                <h3 className="text-lg lg:text-xl font-semibold mb-2">Scholarship/Funding</h3>*/}
            {/*                <p>Scholarships are provided on merits and need basis. Contact for more information.</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*    }*/}

            {/*></BannerF>*/}


            {/*<Banner*/}
            {/*    bg={"bg-secondary"}*/}
            {/*    image={dean}*/}
            {/*    header="Message From Dean"*/}
            {/*    content={Strings.wordsfromDean}*/}
            {/*/>*/}
        </div>
    );
}

export default HomePage;
