import React from 'react';


const cards = (props) => {
    return (

        <div className="bg-gray-400 text-gray-900 rounded-lg shadow-lg mx-2 w-full  mb-8 lg:mb-0 lg:w-1/3 h-96 lg:h-240 flex-col flex items-center justify-center align-middle ">
            {!props.image ?
                <div className={''}>
                    <h2 className="text-2xl font-bold mb-4">{props.title}</h2>
                    <p className="text-lg px-8 ">
                        {props.description}
                    </p>
                </div>
                :
                <div className="relative h-full">
                    <img
                        src={props.image}
                        alt="Banner Image"
                        className="object-cover object-center rounded-lg w-full h-full"
                    />
                    <div className="absolute inset-0 flex  items-center justify-center">
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                        <div className={'flex flex-col md:flex-row justify-evenly items-center px-8 z-50'}>
                            <button className="pl-4 h-16 w-16 rounded-full flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                          d="M15 19l-7-7 7-7"/>
                                </svg>
                            </button>
                            <h2 className="text-7xl font-bold text-white text-center ">{props.title}</h2>
                            {/*    add left and right arrow*/}


                                <button className="pr-4 h-16 w-16 rounded-full flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M9 5l7 7-7 7"/>
                                    </svg>
                                </button>

                        </div>
                    </div>
                </div>

            }
        </div>
    );


}


const BannerWithCards = (props) => {
    return (
        <div className="bg-white my-16 mx-4 md:mx-0  ">
            <div className="container mx-auto flex flex-col lg:flex-row items-center justify-around">
                {(props.dir).map((text) => {
                    return cards(text);
                })}


            </div>
        </div>
    );
};

export default BannerWithCards;
