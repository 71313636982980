import React from 'react';
import Map from '../components/map';
import cover from "../assets/images/cover.jpeg";
import {Strings} from "../assets/string";
import ContactUsPage from "./ContactUsPage";
import BannerWithCards from "../components/BannerWithCards";
import Banner from "../components/Banner";
import BannerF from "../components/BannerF";
import {ImageDir} from "../assets/imageDir";
const bannertexts = [
    {
        title: 'Our Vision',
        description: 'North East Institute of Nursing Science believes in promoting and enhancing the level of education in nursing. The professionals are armed with the latest knowledge, necessary skills, and a humanistic attitude.'
    },

    {
        title: 'Our Mission',
        description: 'Nursing is a noble profession, which makes the professionals be responsible, simple, and efficient. Northeast Institute of Nursing Science is fruitful in developing efficient nurses with adequate knowledge base and clinical expertise.'
    },
    {
        title: 'Our Values',
        description: 'North East Institute of Nursing Science promotes education and professionalism, fostering nurses equipped with current knowledge, skills, and a compassionate ethos, while staying abreast of advancements for quality care.'
    }
]

function AboutPage() {



    return (
        <div className={' bg-white min-h-screen'}>
        <div className="bg-gray-100  p-8 md:pt-16 sm:pt-4">
            <div className="max-w-3xl mx-auto">
                <h1 className={"text-black text-4xl font-extrabold mb-8"}>{Strings.FullName}</h1>

                <p className="text-gray-700 mb-4 text-justify">
                    Welcome to the North East Institute of Nursing Sciences, an esteemed institution under the patronage
                    of the Balaji Educational Trust, established in 2018. Our institute proudly holds affiliations with
                    the Directorate of Medical Education, Government of Tripura, Tripura University, Tripura Nursing
                    Council, and Indian Nursing Council.



                </p>
                <p className="text-gray-700 mb-4 text-justify">
                    At NEINS, our mission is to impart top-tier nursing education, fostering a culture of excellence in
                    clinical practice and deep-seated knowledge. We are committed to nurturing future nurses who are
                    equipped with the skills and expertise to deliver comprehensive health care services encompassing
                    promotion, prevention, cure, and rehabilitation to our society.

                </p>
                <p className="text-gray-700 text-justify">
                    Join us as we embark on a journey of education, service, and compassionate care, shaping the next
                    generation of healthcare professionals.

                </p>

                {/*<div className="container mx-auto py-8 px-4 lg:flex lg:justify-center">*/}
                {/*    <div className="lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">*/}
                {/*        <h2 className="text-2xl font-bold mb-4">Contact Information</h2>*/}
                {/*        <div className="mb-4">*/}
                {/*            <p className="mb-2"><strong>Address:</strong> {Strings.Address}</p>*/}
                {/*            <p className="mb-2"><strong>Phone:</strong> {Strings.Phone}</p>*/}
                {/*            <p className="mb-2"><strong>Email:</strong> {Strings.Email}</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="lg:w-1/2">*/}
                {/*        <Map/>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>

        </div>
            <BannerWithCards dir={bannertexts}/>

            <Banner
                bg={"bg-secondary"}
                image={ImageDir.chairman}
                header={"Chairperson's Message"}
                content={Strings.wordsfromChairman}
            />
            <BannerF
                bg ={'bg-primary'}
                image={ImageDir.principal}
                header={"Principal's Message"}
                content={Strings.wordsfromPrincipal}

                    />
        </div>


    )
}

export default AboutPage;