import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import TextBanner from "./TextBanner";
import { Strings } from "../assets/string";

const tripuraAttractions = [
    {
        image: require('../assets/Tripura/Ujjayanta_Palace.webp'),
        name: "Ujjayanta Palace",
        description: "A majestic royal residence-turned-museum showcasing the rich cultural heritage of Tripura through its architecture, artifacts, and historical significance."
    },
    {
        image: require('../assets/Tripura/Neermahal-Palace_Tripura.webp'),
        name: "Neermahal Palace",
        description: "Renowned as India's largest water palace, sits gracefully amidst the tranquil waters of Rudrasagar Lake, presenting a breathtaking architectural spectacle and serene atmosphere that enchants all who visit."
    },
    // {
    //     image: require('../assets/Tripura/Ujjayanta_Palace.webp'),
    //     name: "Tripura Sundari Temple",
    //     description: "A revered Hindu temple dedicated to Goddess Tripura Sundari, renowned for its exquisite architecture and spiritual significance."
    // },
    {
        image: require('../assets/Tripura/Sepahijala_wildlife_sanctuary.jpeg'),
        name: "Sepahijala Wildlife Sanctuary",
        description: "A biodiverse sanctuary home to a variety of flora and fauna, offering nature trails, wildlife viewing, and boating amidst lush greenery."
    },
    {
        image: require('../assets/Tripura/Unakoti.webp'),
        name: "Unakoti",
        description: "An ancient archaeological site adorned with rock-cut sculptures and carvings dating back to the 7th-9th centuries, depicting Hindu deities and mythological figures."
    },
    {
        image: require('../assets/Tripura/Heritage_Park.jpeg'),
        name: "Heritage Park",
        description: "A charming park showcasing traditional Tripuri architecture, perfect for a leisurely stroll and cultural immersion."
    },
    {
        image: require('../assets/Tripura/Jaganath_temple.jpeg'),
        name: "Jagannath Temple",
        description: "A sacred Hindu temple known for its colorful festivals and religious ceremonies, offering a glimpse into local religious practices."
    },
    {
        image: require('../assets/Tripura/Venuban_Vihar_Temple.jpeg'),
        name: "Venuban Vihar",
        description: "A peaceful Buddhist monastery nestled amidst greenery, offering serenity and spiritual solace to visitors."
    },
    {
        image: require('../assets/Tripura/Dumboor_Lake.jpeg'),
        name: "Dumboor Lake",
        description: "Scenic lake surrounded by lush greenery, offering boating opportunities and picturesque views for nature lovers."
    },
    // {
    //     image: require('../assets/Tripura/Ujjayanta_Palace.webp'),
    //     name: "Ujjayanti Palace",
    //     description: "A treasure trove of artifacts and exhibits showcasing Tripura's cultural heritage, perfect for history enthusiasts and curious minds."
    // }
];

const AttractionItem = ({ name, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b-2 border-gray-200 py-4">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold text-left">{name}</h2>
                <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
                    <svg
                        className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                </button>
            </div>
            {isOpen && <p className="mt-2 text-gray-900 text-left">{description}</p>}
        </div>
    );
};

const AttractionsList = () => {
    return (
        <div className="max-w-2xl mx-auto mt-8">
            <h1 className="text-4xl font-bold mb-2">Discover Tripura</h1>
            <h1 className="text-2xl font-bold mb-6">Where Culture, Nature, and History Unite!</h1>
            <Carousel className={'flex h-1/2 md:h-1/3'} showThumbs={false} swipeable={false} autoPlay={true} interval={3000} infiniteLoop={true}>
                {
               (tripuraAttractions).map((item, index) => (
                   <>
                       <div className={'absolute inset-0 bg-black bg-opacity-40 z-10'}></div>
                       <div className={'relative min-h-fit w-full'} key={index}>
                           <TextBanner
                               title={item.name}
                               description={item.description}
                               className="absolute inset-x-0 bottom-0 px-4 py-8 text-white z-50"
                           />
                           <img src={item.image}
                                alt="Cover"

                                className="inset-0 object-cover object-center w-full " style={{height: '77vh'}}/>
                       </div>
                   </>
               ))

                }

            </Carousel>
            {/* {tripuraAttractions.map((attraction, index) => (
                <AttractionItem key={index} name={attraction.name} description={attraction.description} />
            ))} */}
        </div>
    );
};

export default AttractionsList;
