import React from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import HomePage from './Screens/HomePage';
import ContactUsPage from "./Screens/ContactUsPage";
import NavBar from "./components/NavBar";
import BottomBar from "./components/BottomBar";
import AboutPage from "./Screens/AboutPage";
import ProgramPage from "./Screens/CoursesPage";
import FloatingContactButton from "./components/FloatingContactButton";
import FacilitiesPage from "./Screens/FacilitiesPage";
import RulesRegulationsPage from "./Screens/RulesRegulationsPage";
import CoursesPage from "./Screens/CoursesPage";
import Announcements from "./Screens/Announcements";
import FAQSection from "./Screens/faq";


function App() {
    const location = useLocation();

    const isHomePage = location.pathname === '/';
    console.log('location', isHomePage);
    return (

        <div className="App">
            <NavBar textColor={isHomePage ? 'text-white' : 'text-black'}
                    bordercColor=
                        {isHomePage ? 'border-white' : 'border-gray-300'}

                    bgColor={isHomePage ? 'bg-gray-800' : 'bg-white'
            } />
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="about" element={<AboutPage/>}/>
                <Route path="contact" element={<ContactUsPage/>}/>
                <Route path={"programs"} element={<CoursesPage/>}/>
                <Route path={'facilities'} element={<FacilitiesPage/>}/>
                <Route path={'rules'} element={<RulesRegulationsPage/>}/>
                <Route path={'annc'} element={<Announcements/>}/>
                <Route path={'faq'} element={<FAQSection/>}/>
            </Routes>
            <FloatingContactButton />
            <BottomBar/>
        </div>

    );
}

export default App;