// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAnalytics } from "firebase/analytics";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCx1ZwBcYH23_LXt0kqr6pxK6w7TaJBOWM",
    authDomain: "neins-dc5db.firebaseapp.com",
    projectId: "neins-dc5db",
    storageBucket: "neins-dc5db.appspot.com",
    messagingSenderId: "721251600208",
    appId: "1:721251600208:web:946e6f6ef9d8751a2f4e55",
    measurementId: "G-1LBQWVYBG9"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage();
