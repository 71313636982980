import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
const ContactUsPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState(null);
    const form = useRef();
    const sendEmail = (e) => {
        e.persist();
        e.preventDefault();
        console.log(e.target);

        setIsSubmitting(true);
        emailjs
            .sendForm(
                'service_nko809m',
                "template_vn2inlf",
                e.target,
                'UbzpXlcP5VyQA5T1_'
            )
            .then(
                (result) => {
                    setStateMessage('Message sent!');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                },
                (error) => {
                    setStateMessage('Something went wrong, please try again later');
                    setIsSubmitting(false);
                    setTimeout(() => {
                        setStateMessage(null);
                    }, 5000); // hide message after 5 seconds
                }
            );

        // Clears the form after sending the email
        e.target.reset();
    };
    return (
        <div className="bg-white py-8 min-h-screen lg:py-12">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl lg:text-4xl font-bold mb-6 text-center">Enquiries</h1>
                <form  ref={form} className="max-w-lg mx-auto" onSubmit={sendEmail}>
                    <div className="mb-4">
                        <label htmlFor="firstName" className="block text-gray-700 font-semibold mb-2">Name</label>
                        <input type="text" id="firstName" name="firstName" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" />
                    </div>

                    {/*<div className="mb-4">*/}
                    {/*    <label htmlFor="lastName" className="block text-gray-700 font-semibold mb-2">Last Name</label>*/}
                    {/*    <input type="text" id="lastName" name="lastName" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" />*/}
                    {/*</div>*/}

                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email</label>
                        <input type="email" id="email" name="email" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-700 font-semibold mb-2">Phone</label>
                        <input type="tel" id="phone" name="phone" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500" />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="enquiry" className="block text-gray-700 font-semibold mb-2">Your Enquiry</label>
                        <textarea id="enquiry" name="message" rows="4" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"></textarea>
                    </div>

                    {/*<div className="mb-4">*/}
                    {/*    <label htmlFor="enquiryType" className="block text-gray-700 font-semibold mb-2">Type of Enquiry</label>*/}
                    {/*    <select id="enquiryType" name="enquiryType" className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">*/}
                    {/*        <option value="academic">Academic</option>*/}
                    {/*        <option value="careers">Careers</option>*/}
                    {/*        <option value="others">Others</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}

                    <div className="mb-4">
                        <label htmlFor="agree" className="flex items-center">
                            {/*<input type="checkbox" id="agree" name="agree" className="mr-2" />*/}
                            <span className="text-gray-700 font-semibold">By submitting, you agree to our Terms and Conditions.</span>
                        </label>
                    </div>

                    <div className="text-center bg-primary rounded-2xl">
                        <button type="submit"


                                className=" hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-lg focus:outline-none">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUsPage;
